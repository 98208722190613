<template>
  <v-navigation-drawer
    v-model="drawer"
    :dark="dark"
    width="320"
    :color="color"
    :class="passportTypeClass"
    :mini-variant="mini"
    :mini-variant-width="miniWidth"
    stateless
  >
    <slot></slot>
  </v-navigation-drawer>
</template>

<script>
import '@/css/passportTypes.css'

export default {
  name: 'BaseSideBar',
  data () {
    return {
      drawer: true,
      mini: this.isMini
    }
  },
  props: {
    isMini: Boolean,
    color: String,
    dark: Boolean,
    miniWidth: String,
    passportTypeClass: String
  },
  watch: {
    isMini: {
      handler () {
        this.mini = this.isMini
      }
    }
  }
}
</script>

<style scoped>

</style>
